import React from 'react'

const BannerLanding = (props) => (
    <section id="banner" className="style2">
        <div className="inner">
            <header className="major">
            </header>
            <div className="content">
                <h1 style={{backgroundImage: `url{'https://media.giphy.com/media/9JaEUZGr0h4R2/giphy.gif'}`}}>Hi, I am Sierra</h1>
               
            </div>
        </div>
    </section>
)

export default BannerLanding
