import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'
import Banner from '../components/Banner'

import pic08 from '../assets/images/pic08.jpg'
import pic09 from '../assets/images/pic09.jpg'
import pic10 from '../assets/images/pic10.jpg'

const AboutMe = (props) => (
    <Layout>
        <Helmet>
            <title>About Me</title>
            <meta name="description" content="aboutme Page" />
        </Helmet>

        <BannerLanding />

        <div id="main" style={{backgroundImage: `${'https://media.giphy.com/media/2qVKivAeI8mmk/giphy.gif'}`}}>
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>About Sierra?</h2>
                    </header>
                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <Link><img src={`${'https://media.giphy.com/media/oVzBPkoAa7QSQ/giphy.gif'}`}/></Link>
                    
                    <div className="content">
                        <div className="inner">
                            
                            <p>Everybody is an artist. Bringing passion, dedication, and attention to the things I love, is what I love to do. From web design to fashion to writing music, I believe in making a statement. I took a year long computer programming course through Tacoma Community College. After being introduced to programming, my ability to problem solve and foresee a dream, was revolutionized. I am currently in the Unloop coding bootcamp.</p>
                        </div>
                    </div>
                </section>
                {/* <section>
                    <Link to="/divinity" className="image">
                        <img src={pic09} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Rhoncus magna</h3>
                            </header>
                            <p>Nullam et orci eu lorem consequat tincidunt vivamus et sagittis magna sed nunc rhoncus condimentum sem. In efficitur ligula tate urna. Maecenas massa sed magna lacinia magna pellentesque lorem ipsum dolor. Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis tempus.</p>
                            <ul className="actions">
                                <li><Link to="/divinity" className="button">Learn more</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/divinity" className="image">
                        <img src={pic10} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Sed nunc ligula</h3>
                            </header>
                            <p>Nullam et orci eu lorem consequat tincidunt vivamus et sagittis magna sed nunc rhoncus condimentum sem. In efficitur ligula tate urna. Maecenas massa sed magna lacinia magna pellentesque lorem ipsum dolor. Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis tempus.</p>
                            <ul className="actions">
                                <li><Link to="/divinity" className="button">Learn more</Link></li>
                            </ul>
                        </div>
                    </div>
                </section> */}
            </section>
        </div>

    </Layout>
)

export default AboutMe